import { Component, Input, Output, EventEmitter, OnInit, OnChanges } from '@angular/core';
import { FormControl, Validators, FormGroup } from '@angular/forms';

@Component({
  selector: 'wn-select-filter',
  templateUrl: './select-filter.component.html',
  styleUrls: ['./select-filter.component.scss'],
})
export class SelectFilterComponent implements OnInit, OnChanges {

  @Input() store: Array<string> = [];
  @Input() placeHolder: string = '';
  @Input() selected: string;
  @Output() selectChange = new EventEmitter();

  public data: Array<string> = [];
  public selectForm = new FormGroup({
    select: new FormControl('', Validators.required)
  });

  ngOnInit() {
    this.data = this.store;
    if (this.selected) {
      this.selectForm.patchValue({select: this.selected})
    }
  }

  ngOnChanges() {
    this.data = this.store;
    if (!this.selected) {
      this.selectForm.patchValue({select: ''})
    }
  }

  search(query: string) {
    this.data = this.store.filter(el => el.toLowerCase().indexOf(query.toLowerCase()) > -1)
  }

  selectChangeOption(event: any) {
    this.selectChange.emit(event.value);
  }
}
