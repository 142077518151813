import { Component, Input, Output, EventEmitter, OnChanges, OnDestroy } from '@angular/core';
import { AuthsessionService } from '@shared/services/auth/authsession.service';
import { SimulatorService } from '@shared/services/http/simulator.service';
import { ModalService } from '@shared/services/modal/modal.service';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'wn-credit-quote',
  templateUrl: './credit-quote.component.html',
  styleUrls: ['./credit-quote.component.scss']
})
export class CreditQuoteComponent implements OnChanges, OnDestroy {

  @Input() config: any;
  @Input() carCost: number;
  @Input() creditPeriodPayment: number = 48;
  @Output() setListMonthly = new EventEmitter();
  @Output() selectPeriodPayment = new EventEmitter();
  @Output() updateCreditAmount = new EventEmitter();
  @Output() setDownPayment = new EventEmitter();

  private keyMonthlyPayment = new Subject<string>();
  private keyDownPayment = new Subject<string>();

  private firstLoad: boolean = true;
  private subsMonthlyPayment: Subscription;
  private subsDownPayment: Subscription;
  private oldCarCost: number;
  public creditAmount: number;
  public creditDownPayment: number = 0;
  public creditDownPaymentPercent: number = 30;
  public minDownPayment: number;
  public maxDownPayment: number;
  public minDownPaymentPercentage: number = 25;
  public maxDownPaymentPercentage: number = 90;
  public minCreditAmount: number = 20000;
  public maxCreditAmount: number = 490000;
  public periodsPayments = [12, 24, 48, 60];
  public minPeriodPayment: number = 12;
  public maxPeriodPayment: number = 60;
  public minMonthlyPayment: number;
  public maxMonthlyPayment: number;
  public monthlyPayment: number = 0;
  public monthlyPayments: Array<any> = [];
  public queryParams: any;

  constructor(
    private simulatorService: SimulatorService,
    private dialogService: ModalService,
    private activatedRoute: ActivatedRoute
  ) {
    this.queryParams = this.activatedRoute.snapshot.queryParams;
    this.subsMonthlyPayment = this.keyMonthlyPayment.pipe(
      debounceTime(900),
      distinctUntilChanged())
      .subscribe(() => {
        this.getSimulatorChangeMonthlyPayment();
      });
    this.subsDownPayment = this.keyDownPayment.pipe(
      debounceTime(900),
      distinctUntilChanged())
      .subscribe((event: any) => {
        if(event.code) {
          this.creditDownPaymentPercent = (this.creditDownPayment * 100) / this.carCost;
        } 
        this.changeCreditAmount()
      });
  }

  ngOnChanges() {
    this.setConfigValues();
    this.changePeriodPayment(this.creditPeriodPayment);
    this.loadData();
    if (this.carCost > 0 && this.oldCarCost != this.carCost) {
      this.changeCreditAmount();
    }
  }

  public loadData(){
    if(this.queryParams && this.queryParams.credit_car_cost) {
      this.carCost = parseFloat(this.queryParams.credit_car_cost.replace(',', '', '/g'));
      this.creditDownPayment = parseFloat(this.queryParams.credit_down_payment.replace(',', '', '/g'));
    }
  }

  public changePeriodPayment(item: any) {
    this.creditPeriodPayment = item.value ? item.value : item;
    this.monthlyPayments.map(monthlyPayment => monthlyPayment.active = monthlyPayment.payment == this.creditPeriodPayment)
    let monthlyPaymentFind = this.monthlyPayments.find(monthlyPayment => monthlyPayment.active);
    if (monthlyPaymentFind) {
      this.monthlyPayment = monthlyPaymentFind.payment_amount
      this.simulatorService.setMonthlyPayment(this.monthlyPayment)
    }
  }


  public setConfigValues() {
    if (this.config) {
      let lenMonthlty = this.config.periods_payment.length;
      this.periodsPayments = this.config.periods_payment.map(period => parseInt(period));
      let defaultPeriodPayment = parseInt(this.config.defaults.period_payment);
      let defaultDownPaymentPercent = parseInt(this.config.defaults.down_payment);
      this.minDownPaymentPercentage = parseInt(this.config.down_payment.min_percentage);
      this.minCreditAmount = parseInt(this.config.loan_amount.min);
      this.maxCreditAmount = parseInt(this.config.loan_amount.max);
      this.minPeriodPayment = parseInt(this.config.periods_payment[0])
      this.maxPeriodPayment = parseInt(this.config.periods_payment[lenMonthlty - 1])
      if (this.firstLoad) {
        this.creditDownPaymentPercent = defaultDownPaymentPercent;
        this.creditPeriodPayment = defaultPeriodPayment;
      }
      this.firstLoad = false
    }
  }

  public getValues() {
    let serviceData: User = {
      credit_amount: this.creditAmount,
      credit_period_payment: this.creditPeriodPayment,
      credit_monthly_payment: this.monthlyPayment,
      credit_down_payment: this.creditDownPayment,
      car_cost: this.carCost,
    };
    return serviceData;
  }

  public getSimulationInit() {
    let modal = this.dialogService.loader();
    let params: Simulator = {
      credit_amount: this.creditAmount,
      credit_car_cost: this.carCost,
      credit_period_payment: this.creditPeriodPayment,
      credit_down_payment: this.creditDownPayment
    }
    if (this.creditAmount && this.creditPeriodPayment && this.creditDownPayment) {
      this.simulatorService.getSimulationCredit(params).then(
        success => {
          this.monthlyPayments = success.data.payments;
          for (let index = 0; index < this.monthlyPayments.length; index++) {
            this.monthlyPayments[index].active = false;
            if (index == this.monthlyPayments.length - 1) {
              this.minMonthlyPayment = this.monthlyPayments[index].payment_amount
            } else if (index == 0) {
              this.maxMonthlyPayment = this.monthlyPayments[index].payment_amount
            }
            if (this.creditPeriodPayment == this.monthlyPayments[index].payment) {
              this.monthlyPayment = this.monthlyPayments[index].payment_amount;
              this.monthlyPayments[index].active = true;
            }
          }
          this.setListMonthly.emit(this.monthlyPayments);
          this.updateCreditAmount.emit(this.creditAmount)
          modal.close()
        },
        error => {
          console.error(error)
          modal.close()
        }
      )
    }
  }

  public getSimulatorChangeMonthlyPayment() {
    let modal = this.dialogService.loader();
    let params: Simulator = {
      credit_amount: this.creditAmount,
      credit_car_cost: this.carCost,
      credit_period_payment: this.creditPeriodPayment,
      credit_monthly_payment: this.monthlyPayment
    }
    this.simulatorService.getSimulationCredit(params).then(
      success => {
        this.monthlyPayments = success.data.payments;
        this.creditDownPayment = success.data.credit_down_payment;
        this.creditDownPaymentPercent = (this.creditDownPayment * 100) / this.carCost;
        for (let index = 0; index < this.monthlyPayments.length; index++) {
          this.monthlyPayments[index].active = false;
          if (this.creditPeriodPayment == this.monthlyPayments[index].payment) {
            this.monthlyPayment = this.monthlyPayments[index].payment_amount;
            this.monthlyPayments[index].active = true;
          }
        }
        this.setListMonthly.emit(this.monthlyPayments);
        this.updateCreditAmount.emit(this.creditAmount)
        this.changeCreditAmount();
        modal.close()
      },
      error => {
        modal.close()
        this.dialogService.setTextTitle('Monto total excedido').setTextMessage(`Disminuye el monto del pago mensual o los plazos.`).error()
        let monthlyPayment = this.monthlyPayments.find(monthlyPayment => monthlyPayment.active)
        this.monthlyPayment = monthlyPayment.payment_amount
      }
    )
  }

  public changeCreditAmount() {
    this.oldCarCost = this.carCost;
    this.calculateLimitsDownPayment();
    this.calculateDownPayment();
    this.calculateCreditAmount();
    this.getSimulationInit();
  }

  public calculateLimitsDownPayment() {
    this.minDownPayment = this.carCost * (this.minDownPaymentPercentage / 100);
    if((this.carCost - this.minDownPayment) > this.maxCreditAmount) {
      this.minDownPayment = this.carCost - this.maxCreditAmount
    }
    this.maxDownPayment = this.carCost - this.minCreditAmount;
    this.maxDownPaymentPercentage = (this.carCost - this.minCreditAmount) * 100 / this.carCost
  }

  public calculateDownPayment() {
    let creditDownPayment = this.carCost * (this.creditDownPaymentPercent / 100);
    this.creditDownPayment = creditDownPayment > this.maxDownPayment || creditDownPayment < this.minDownPayment ? 
      this.minDownPayment : creditDownPayment;
    this.creditDownPaymentPercent = (this.creditDownPayment * 100) / this.carCost;
    this.setDownPayment.emit(this.creditDownPayment)
  }

  public calculateCreditAmount() {
    this.creditAmount = this.carCost - this.creditDownPayment;
  }

  ngOnDestroy() {
    this.subsMonthlyPayment.unsubscribe();
    this.subsDownPayment.unsubscribe();
  }


}
