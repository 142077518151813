import { Component, OnInit, Inject, Input } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { UserService } from '@shared/services/http/user.service';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { LoaderComponent } from '../loader/loader.component';

@Component({
  selector: 'wn-resend-activated',
  templateUrl: './resend-activated.component.html',
  styleUrls: ['./resend-activated.component.scss']
})
export class ResendActivatedComponent implements OnInit {
  @Input() modalTitle:string = "";
  email;
  formResend:FormGroup
  constructor(public dialogRef: MatDialogRef<ResendActivatedComponent>,
    @Inject(MAT_DIALOG_DATA) public data, private userSvc:UserService, private formBuilder:FormBuilder) { }

  ngOnInit() {
    this.makeForm()
  }

  closeModal()
  {
    this.dialogRef.close()    
  }

  resend()
  {
    this.dialogRef.close()
    this.userSvc.resendOtp('', this.formResend.value.email).then(success => {
      console.log(success)
    }, error => {
      console.log(error)
    })

  }

  makeForm()
  {
    this.formResend = this.formBuilder.group({
      email: new FormControl('', [Validators.required, Validators.email])
    })
  }
}
