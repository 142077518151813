import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { Subject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class SimulatorService {


  private monthlyPayment = new Subject();

  constructor(private httpSvc: HttpService) { }

  getFactoryCredit() {
    return this.httpSvc.get('simulator/product').toPromise();
  }

  getSimulationCredit(items: Simulator) {
    return this.httpSvc.post('simulator', items).toPromise();
  }

  get getMonthlyPayment(){
    return this.monthlyPayment
  }

  public setMonthlyPayment(payment: any){
    this.monthlyPayment.next(payment)
  }
}
