import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { CarListService } from '@shared/services/http/car-list.service'
import { SIMULATOR } from '@shared/data/data'
import { AuthsessionService } from '@shared/services/auth/authsession.service';
import { ModalService } from '@shared/services/modal/modal.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'wn-category-cars',
  templateUrl: './category-cars.component.html',
  styleUrls: ['./category-cars.component.scss']
})
export class CategoryCarsComponent implements OnInit {

  @Input() loadMemory = false;
  @Output() setPrice = new EventEmitter();
  @Output() values = new EventEmitter();

  public brand: string;
  public model: string;
  public version: string;
  public year: string;
  public carCost: number = 0;

  public queryParams: any;
  public cars = [];
  public years = [];
  public brands = []
  public models = []
  public versions = []

  constructor(
    private carListSvc: CarListService,
    private sessionSvc: AuthsessionService,
    private modalSvc: ModalService,
    private activatedRoute: ActivatedRoute
  ) {
    this.queryParams = this.activatedRoute.snapshot.queryParams;
  }

  ngOnInit() {
    this.getCars();
    this.loadData();
  }

  public loadData() {
    if (this.queryParams && this.queryParams.credit_car_cost) {
      this.carCost = parseFloat(this.queryParams.credit_car_cost.replace(',', '', '/g'));
    }
  }

  public getCars() {
    let modal: any;
    if (!this.loadMemory) {
      modal = this.modalSvc.loader();
    } else {
      this.year = this.getKeyCar('year')
      this.brand = this.getKeyCar('brand')
      this.model = this.getKeyCar('model')
      this.version = this.getKeyCar('version')
      this.carCost = this.getKeyCar('carCost') || 0 
    }
    this.carListSvc.getCarsList().then(
      response => {
        this.cars = response.data;
        this.years = [...new Set(this.cars.map((car: any) => car.model_years))]
        this.brands = [...new Set(this.cars.map((car: any) => car.make_name))]
        this.models = [...new Set(this.cars.map((car: any) => car.model_name))]
        this.versions = [...new Set(this.cars.map((car: any) => car.version))]
        if (!this.loadMemory) {
          modal.close();
        }
      },
      error => {
        console.error(error);
        if (!this.loadMemory) {
          modal.close();
        }
      }
    )
  }

  public filterOptions(field: string, text: string) {
    this.carListSvc.getCarsListFilter(field, text).then(response => {
      switch (field) {
        case SIMULATOR.YEAR:
          this.brand = null;
          this.model = null;
          this.version = null;
          this.carCost = 0;
          this.year = text;
          this.setBrandCars(response.data)
          break;
        case SIMULATOR.BRAND:
          this.model = null;
          this.version = null;
          this.carCost = 0;
          this.brand = text;
          this.setModelCars(response.data)
          break;
        case SIMULATOR.NAME:
          this.version = null;
          this.carCost = 0;
          this.model = text
          this.setVersionCars(response.data)
          break;
        case SIMULATOR.VERSION:
          this.carCost = 0;
          this.version = text
          this.setValueCarCost(this.getCar());
          break;
      }
    }, error => {
      console.error(error)
    })
  }

  public setBrandCars(items: Array<any>) {
    this.brands = [...new Set(items.map((car: any) => car.make_name))]
  }

  public setModelCars(items: Array<any>) {
    this.models = [...new Set(items.map((car: any) => car.model_name))]
  }

  public setVersionCars(items: Array<any>) {
    this.versions = [...new Set(items.map((car: any) => car.version))]
  }

  public setValueCarCost(car: any) {
    if(car) {
      this.carCost = parseFloat(car.price)
      this.setPrice.emit(this.carCost)
      this.setValues()
    } else {
      this.brand = null;
      this.model = null;
      this.version = null;
    }
  }

  public getCar() {
    let car = this.cars.find(car => car.model_years == this.year &&
      car.make_name == this.brand && car.model_name == this.model && car.version == this.version);
    return car
  }

  public setValues() {
    this.values.emit({
      brand: this.brand,
      model: this.model,
      version: this.version,
      year: this.year,
      carCost: this.carCost
    });
  }

  public getKeyCar(key: string) {
    let memory = this.sessionSvc.setNameSession("selectCar").getAllData();
    return memory && memory.hasOwnProperty(key) ? memory[key] : null
  }

}
