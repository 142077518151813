import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../material/material.module';
import { CreditQuoteComponent } from './components/credit-quote/credit-quote.component';
import { MonthlyPaymentsComponent } from './components/monthly-payments/monthly-payments.component';
import { NgxCurrencyModule } from "ngx-currency";
import { FormsModule } from '@angular/forms'
import { ReactiveFormsModule} from '@angular/forms'

import { HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SelectFilterComponent } from './components/select-filter/select-filter.component';
import { CategoryCarsComponent } from './components/category-cars/category-cars.component';
import { SuccessComponent } from './services/modal/success/success.component';
import { ConfirmComponent } from './services/modal/confirm/confirm.component';
import { ErrorComponent } from './services/modal/error/error.component';
import { NotificationComponent } from './services/modal/notification/notification.component';
import { AmortizationTableComponent } from './components/amortization-table/amortization-table.component';
import { LoaderComponent } from './services/modal/loader/loader.component';
import { ShareAmortizationTableComponent } from './components/share-amortization-table/share-amortization-table.component';
import { CreditBureauAuthorizationComponent } from '@shared/components/credit-bureau-authorization/credit-bureau-authorization.component';

import { LottieAnimationViewModule } from 'ng-lottie';
import { NavigationBarComponent } from './components/layout/navigation-bar/navigation-bar.component';
import { FooterComponent } from './components/layout/footer/footer.component';
import { InputPassComponent } from './components/input-pass/input-pass.component';
import { ValidPasswordComponent } from './components/valid-password/valid-password.component';
import { ApplicationDataSendComponent } from '@shared/components/application-data-send/application-data-send.component';
import { OfficialIdentificationComponent } from './components/official-identification/official-identification.component';
import { IncomeProofComponent } from './components/income-proof/income-proof.component';
import { AddressProofComponent } from './components/address-proof/address-proof.component';
import { InputFileComponent } from './components/input-file/input-file.component';
import { TermsConditionsComponent } from './components/terms-conditions/terms-conditions.component';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';
import { ZipCodeDirective } from './directives/ZipCode/zip-code.directive';
import { ResendActivatedComponent } from './services/modal/resend-activated/resend-activated.component';
import { NumbersDirective } from './directives/numbers/numbers.directive';


export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}


@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    NgxCurrencyModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      },
      isolate: true
    }),
    LottieAnimationViewModule.forRoot()
  ],
  declarations: [
    CreditQuoteComponent,
    MonthlyPaymentsComponent,
    CategoryCarsComponent,
    SelectFilterComponent,
    SuccessComponent,
    ConfirmComponent,
    ErrorComponent,
    NotificationComponent,
    AmortizationTableComponent,
    LoaderComponent,
    ShareAmortizationTableComponent,
    CreditBureauAuthorizationComponent,
    NavigationBarComponent,
    FooterComponent,
    ApplicationDataSendComponent,
    OfficialIdentificationComponent,
    IncomeProofComponent,
    AddressProofComponent,
    InputPassComponent,
    ValidPasswordComponent,
    ApplicationDataSendComponent,
    InputFileComponent,
    TermsConditionsComponent,
    PrivacyPolicyComponent,
    ZipCodeDirective,
    ResendActivatedComponent,
    NumbersDirective
  ],
  entryComponents: [
    SuccessComponent,
    ErrorComponent,
    ConfirmComponent,
    NotificationComponent,
    AmortizationTableComponent,
    LoaderComponent,
    ShareAmortizationTableComponent,
    CreditBureauAuthorizationComponent,
    ApplicationDataSendComponent,
    OfficialIdentificationComponent,
    IncomeProofComponent,
    AddressProofComponent,
    InputPassComponent,
    InputFileComponent,
    ApplicationDataSendComponent,
    TermsConditionsComponent,
    PrivacyPolicyComponent,
    ResendActivatedComponent
  ],
  exports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    NgxCurrencyModule,
    MaterialModule,
    CreditQuoteComponent,
    MonthlyPaymentsComponent,
    CategoryCarsComponent,
    SelectFilterComponent,
    SuccessComponent,
    ConfirmComponent,
    ErrorComponent,
    NotificationComponent,
    LottieAnimationViewModule,
    NavigationBarComponent,
    FooterComponent,
    InputPassComponent,
    ValidPasswordComponent,
    InputFileComponent,
    TermsConditionsComponent,
    PrivacyPolicyComponent,
    ZipCodeDirective,
    NumbersDirective
  ]
})
export class SharedModule {
  constructor(private translate: TranslateService) {
    translate.addLangs(['es']);
    translate.setDefaultLang('es');
    translate.use('es');
  }
}
