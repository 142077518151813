import { AuthsessionService } from './../../../services/auth/authsession.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalService } from '@shared/services/modal/modal.service';

@Component({
  selector: 'wn-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  sessionActive = true;
  constructor(private route: Router, private modalSvc: ModalService, private sessionservice: AuthsessionService ) {
    const user = this.sessionservice.setNameSession('user').getAllData();
    if(user)
    {
      this.sessionActive = false;
    }
  }

  ngOnInit() {
  }

  openLink(link){
    switch (link) {
      case '/faqs':
        this.route.navigate([]).then(result => {  window.open(link, '_blank'); });
        break;
      default:
        this.route.navigate([link]);
        break;
    }
  }
  showTermsAndConditions() {
    this.modalSvc.setHeigth("448px").setWidth("755px").termsAndConditions();
  }

  showPrivacyPolicy() {
    this.modalSvc.setHeigth("448px").setWidth("755px").privacyPolicy();
  }

}
