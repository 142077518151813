import { Component, Input, Output, EventEmitter, OnChanges, OnDestroy } from '@angular/core';
import { Router } from '@angular/router'
import { SimulatorService } from '@shared/services/http/simulator.service';
import { Subscription } from 'rxjs';
import { AuthsessionService } from '@shared/services/auth/authsession.service';

@Component({
  selector: 'wn-monthly-payments',
  templateUrl: './monthly-payments.component.html',
  styleUrls: ['./monthly-payments.component.scss']
})
export class MonthlyPaymentsComponent implements OnChanges, OnDestroy {

  @Input() gpsRent: number;
  @Input() creditAmount: number = 0;
  @Input() comisionPercentage: number = 0;
  @Input() monthlyPayments: Array<any> = []
  @Output() periodSelect = new EventEmitter();
  @Output() saveSimulation = new EventEmitter();

  public openningCommission: number;
  public payment: number = 0;
  private subPayment: Subscription;
  private user: any;

  constructor(
    private router: Router,
    private simulatorService: SimulatorService,
    private authSessionService: AuthsessionService
  ) {
    this.user = this.authSessionService.setNameSession('userSession').getAllData();
    this.subPayment = this.simulatorService.getMonthlyPayment.subscribe(
      (payment: number) => this.payment = payment
    );
  }

  ngOnChanges() {
    this.openningCommission = this.creditAmount * (this.comisionPercentage / 100);
    this.monthlyPayments.forEach(payment => {
      if(payment.active) {
        this.payment = payment.payment_amount
      }
    })
  }

  goToRegistry() {
    this.saveSimulation.emit();
    let url = this.user ? '/application' : '/auth/register';
    this.router.navigate([url])
  }

  updatePeriod(item: any) {
    this.payment = item.payment_amount;
    if (item) {
      this.periodSelect.emit(item.payment)
    }
  }

  ngOnDestroy() {
    this.subPayment.unsubscribe();
  }

}
