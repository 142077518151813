import { Injectable } from '@angular/core';
import * as S3 from 'aws-sdk/clients/s3';
import { environment } from 'environments/environment'
import { UserService } from '../http/user.service';
import { HttpService } from '../http/http.service';

@Injectable({
  providedIn: 'root'
})
export class GetUrlFileService {

  constructor(private userSvc:UserService, private httpSvc:HttpService) { }

  getUrl(path, name)
  {
    let pathName = "";
    let credentials;
    if(path != "" && typeof path != "undefined")
    {
      pathName = path+"/"+name
    }else
    {
      pathName = name
    }
    const bucket = new S3({
      accessKeyId: credentials.accessKeyId,
      secretAccessKey: credentials.secretAccessKey,
      sessionToken: credentials.sessionToken,
      region: environment.bucket.region
    });
    const params = {
      Bucket: environment.bucket.name,
      Key: pathName
    };

    return bucket.getSignedUrl('getObject', params);
  }

  uploadFile(file, nameFiled) {

    let path = "document";
    let base64 =file.replace(/^data:image\/[a-z]+;base64,/, ""); 
    let header = this.httpSvc.loadToken();
    let params = {
      "name_document": nameFiled,
      "document": base64
    }
    return this.httpSvc.put(path, params, header).toPromise()
  }

  uploadFileS3(file, type) {
    const contentType = file.type;
    this.userSvc.getUserData().then(user => {
      console.log(user.data.id)
      this.userSvc.userGetTokensS3().then(token => {
        console.log(token)
        const bucket = new S3(
          {
            accessKeyId: token.access_key,
            secretAccessKey: token.secret_key,
            sessionToken: token.access_token,
            region: environment.bucket.region      
          }
      );
      let pathFileName = user.data.id+"/"+ file.name;
      const params = {
        Bucket: environment.bucket.name,
        Key: pathFileName,
        Body: file,
        ContentType: contentType
      };
      console.log(params)
      console.log(bucket)
      let result = bucket.upload(params, function (err, data) {
        if (err) {
            console.log('There was an error uploading your file: ', err);
            return false;
        }
        console.log('Successfully uploaded file.', data);
        return true;
      });

      console.log(result)
    })


    })

    /*

      */
      //for upload progress   
      /*bucket.upload(params).on('httpUploadProgress', function (evt) {
                console.log(evt.loaded + ' of ' + evt.total + ' Bytes');
            }).send(function (err, data) {
                if (err) {
                    console.log('There was an error uploading your file: ', err);
                    return false;
                }
                console.log('Successfully uploaded file.', data);
                return true;
            });*/
  }
}
