import { Component, forwardRef, OnInit, OnChanges, OnDestroy, HostBinding, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { Subject } from 'rxjs';
@Component({
  selector: 'wn-input-pass',
  templateUrl: './input-pass.component.html',
  styleUrls: ['./input-pass.component.scss'],
  providers:[{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => InputPassComponent),
    multi: true
  }]
})
export class InputPassComponent implements OnInit, OnChanges, OnDestroy, ControlValueAccessor {

  iconShow = "visibility";
  iconHide = "visibility_off";
  inputPass = "password";
  inputText = "text";
  static nextId = 0;
  _value="";
  icon;
  type;
  state:boolean = false;
  stateChanges = new Subject<void>();
  @HostBinding() id = `pass-input-${InputPassComponent.nextId++}`;

  onTouched: () => void = () => {};
  onChange: (_: any) => void = (_: any) => {};  

  @Input()
  get placeholder() {
    return this._placeholder;
  }
  set placeholder(plh) {
    this._placeholder = plh;
    this.stateChanges.next();
  }
  private _placeholder: string;  

  constructor() { 
    this.icon = this.iconShow;
    this.type = this.inputPass;
  }

  @Input() set value(value: string) {
    this._value = value == null ? '' : String(value);
  }
  get value() {
    return this._value;
  }


  ngOnInit() {
  }

  ngOnChanges(){}

  ngOnDestroy() {
    this.stateChanges.complete();
  }

  propagateChange = (_: any) => {};
  updateChanges() {
    this.onChange(this._value);
  }
  get empty() {

    return !this._value;
  }

  valueChanged(value)
  {
    this._value =  value.target.value;
    this.onChange(this._value)
  }

  writeValue(value): void {
      this._value = value;

      if (value) {
        this._value = value || '';
      } else {
        this._value = '';
      }
      this.updateChanges();
  }

  registerOnChange(fn: any): void {
      this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
      this.onTouched = fn;
  }

  changeType()
  {
    this.state = !this.state;
    if(this.state)
    {
      this.icon = this.iconHide;
      this.type = this.inputText;
    }else
    {
      this.icon = this.iconShow;
      this.type = this.inputPass;
    }
  }

}
