import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ZipCodeHttpService {
  url:string;

  constructor(private httpClient: HttpClient) { 
    this.setUrl(environment.zipCodeUrl)
  }

  setUrl(url){
    this.url = url;
    return this;
  }

  getUrl(){
    return this.url;    
  }


  getZipCode(zipCode)
  {
    let url = this.getUrl()+"?zip_code="+zipCode;
    return this.httpClient.get(url).toPromise();
  }

  objToQueryString(obj:Object)
  {
    var str = [];
    for (var p in obj)
      if (obj.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
      }
    return str.join("&");
  }
}
