import { Component, forwardRef, OnInit, OnChanges, OnDestroy, HostBinding, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { Subject } from 'rxjs';
@Component({
  selector: 'wn-valid-password',
  templateUrl: './valid-password.component.html',
  styleUrls: ['./valid-password.component.scss']
})
export class ValidPasswordComponent implements OnInit, OnChanges, OnDestroy, ControlValueAccessor  {
  static nextId = 0;
  _value="";
  confirmpass;
  password;
  activeComparePass = false;
  stateChanges = new Subject<void>();
  @HostBinding() id = `pass-input-${ValidPasswordComponent.nextId++}`;
  onTouched: () => void = () => {};
  onChange: (_: any) => void = (_: any) => {};  

  @Input()
  get placeholder() {
    return this._placeholder;
  }
  set placeholder(plh) {
    this._placeholder = plh;
    this.stateChanges.next();
  }
  private _placeholder: string;  

  constructor() { }

  @Input() set value(value: string) {
    this._value = value == null ? '' : String(value);
  }
  get value() {
    return this._value;
  }

  ngOnInit() {
  }

  ngOnChanges(){}

  ngOnDestroy() {
    this.stateChanges.complete();
  }

  propagateChange = (_: any) => {};
  updateChanges() {
    this.onChange(this._value);
  }
  get empty() {

    return !this._value;
  }

  valueChanged(value)
  {
    this._value =  value.target.value;
    this.onChange(this._value)
  }

  writeValue(value): void {
      this._value = value;

      if (value) {
        this._value = value || '';
      } else {
        this._value = '';
      }
      this.updateChanges();
  }

  registerOnChange(fn: any): void {
      this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
      this.onTouched = fn;
  }

}
