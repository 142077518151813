import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { UserService } from '@shared/services/http/user.service';
import { AuthsessionService } from '@shared/services/auth/authsession.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'baseAngular';

  constructor(
    public translate: TranslateService,
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer,
    private userService: UserService,
    private sessionService: AuthsessionService
  ) {
    let user = this.sessionService.setNameSession('user').getAllData();
    if(user){
      this.userService.getUserData().then(
        response => this.sessionService.setNameSession('user').setDataSession(response.data),
        error => console.error(error)
      );
    }
    translate.addLangs(['es']);
    translate.setDefaultLang('es');
    translate.use('es');

    iconRegistry.addSvgIcon('wanacar-logo', sanitizer.bypassSecurityTrustResourceUrl('assets/images/wanacar-logo.svg'));
    iconRegistry.addSvgIcon('fundacion-logo', sanitizer.bypassSecurityTrustResourceUrl('assets/images/fundacion-logo.svg'));
    iconRegistry.addSvgIcon('facebook-logo', sanitizer.bypassSecurityTrustResourceUrl('assets/images/facebook-logo.svg'));
    iconRegistry.addSvgIcon('whatsapp-logo', sanitizer.bypassSecurityTrustResourceUrl('assets/images/whatsapp-logo.svg'));
    iconRegistry.addSvgIcon('twitter-logo', sanitizer.bypassSecurityTrustResourceUrl('assets/images/twitter-logo.svg'));
    iconRegistry.addSvgIcon('instagram-logo', sanitizer.bypassSecurityTrustResourceUrl('assets/images/instagram-logo.svg'));
    iconRegistry.addSvgIcon('expand-icon', sanitizer.bypassSecurityTrustResourceUrl('assets/images/icon-expand.svg'));
    iconRegistry.addSvgIcon('collapse-icon', sanitizer.bypassSecurityTrustResourceUrl('assets/images/icon-collapse.svg'));
  }

}
