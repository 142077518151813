import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'wn-official-identification',
  templateUrl: './official-identification.component.html',
  styleUrls: ['./official-identification.component.scss']
})
export class OfficialIdentificationComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<OfficialIdentificationComponent>) { }

  ngOnInit() {
  }

  closeModal() {
    this.dialogRef.close();
  }

}
