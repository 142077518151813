import { Component, Input } from '@angular/core';
import { GetUrlFileService } from '@shared/services/s3/get-url-file.service';
import { AuthsessionService } from '@shared/services/auth/authsession.service';
import { USER_SESSION } from '@shared/data/data';
import { UserService } from '@shared/services/http/user.service';

@Component({
  selector: 'wn-input-file',
  templateUrl: './input-file.component.html',
  styleUrls: ['./input-file.component.scss']
})
export class InputFileComponent {
  @Input() elements: number = 1;
  @Input() vertical: boolean = false;
  @Input() label: string = 'Frente*';
  @Input() type: string;
  dataImgs1;
  dataImgs2;
  dataImgs3;

  public isErrorSize = false;


  documentType = {

  }
  constructor(
    private fileSvc: GetUrlFileService,
    private sessionSvc: AuthsessionService,
    private userSvc: UserService
  ) { }

  upload(items, numberImgs?: number) {
    if (!this.isValidateSizeImage(items)) { return; }
    let reader = new FileReader();
    if (!numberImgs) {
      numberImgs = 1;
    }
    let _this = this;
    reader.onload = () => {
      if (numberImgs == 1) {
        _this.dataImgs1 = reader.result
      }
      if (numberImgs == 2) {
        _this.dataImgs2 = reader.result
      }
      if (numberImgs == 3) {
        _this.dataImgs3 = reader.result
      }
      _this.sendSvc(reader.result, items.target.files[0].name, numberImgs)
    }
    reader.readAsDataURL(items.target.files[0])
  }

  sendSvc(base64: string | ArrayBuffer, nameFile: string, numberImgs: number) {
    let nameField: string;
    let typeDocument: string;
    let field: string;

    switch (this.type) {
      case "proof":
        switch (numberImgs) {
          case 1:
            nameField = "last_salary_slips";
            break;
          case 2:
            nameField = "antepenultimate_salary_slips";
            break;
          case 3:
            nameField = "previus_salary_slips";
            break;
        }
        field = nameField;
        break;
      case "ine":
        typeDocument = this.type
        if (numberImgs == 1) {
          nameField = this.type + "_" + "id_front"
          field = "id_front"
        } else if (numberImgs == 2) {
          nameField = this.type + "_" + "id_back"
          field = "id_back"
        }
        break;
      case "professional_id":
        typeDocument = this.type
        if (numberImgs == 1) {
          nameField = this.type + "_" + "id_front"
          field = "id_front"
        }
        break;
      case "passport":
        typeDocument = this.type
        if (numberImgs == 1) {
          nameField = this.type + "_" + "id_front"
          field = "id_front"
        }
        break;
      case "address":
        if (numberImgs == 1) {
          nameField = "address_proof"
          field = nameField
        }
        break;
    }
    this.fileSvc.uploadFile(base64, nameField + "_" + nameFile).then(success => {
      let id = this.sessionSvc.setNameSession(USER_SESSION).getKeySession("id")
      let params = {}
      params["name_document"] = id + "/" + nameField + "_" + nameFile
      params["type_document"] = field;
      this.userSvc.updateDocument(params).then(response => { }, err => {
        console.error(err)
      })
    }, error => {
      console.error(error)
    })
  }

  public isValidateSizeImage(items: any) {
    this.isErrorSize = items.target.files[0].size > 10485760;
    return !this.isErrorSize
  }


}
