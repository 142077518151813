import { AuthsessionService } from './../../services/auth/authsession.service';
import { Component, OnInit, Input } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { USER_SESSION } from '@shared/data/data';

@Component({
  selector: 'wn-credit-bureau-authorization',
  templateUrl: './credit-bureau-authorization.component.html',
  styleUrls: ['./credit-bureau-authorization.component.scss']
})
export class CreditBureauAuthorizationComponent implements OnInit {
  @Input() title:string ="";
  authpass;
  validPass;

  constructor(public dialogRef: MatDialogRef<CreditBureauAuthorizationComponent>, private formBuilder:FormBuilder, private sessionService:AuthsessionService, private router:Router) { }

  ngOnInit() {
    this.makeForm()
  }

  makeForm(){
    this.validPass = this.formBuilder.group({
      authpass: new FormControl('', [Validators.required])
    })
  }

  goToResetPass()
  {

    this.sessionService.setNameSession(USER_SESSION).clearSession();
    this.router.navigate(['/auth/resetpassword']);
    this.dialogRef.close();
  }

  closeModal() {
    this.dialogRef.close();
  }

  btnAccept(){
    this.dialogRef.close(this.validPass.value);
  }

}
