import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { SuccessComponent } from './success/success.component'
import { ErrorComponent } from './error/error.component'
import { ConfirmComponent } from './confirm/confirm.component';
import { AmortizationTableComponent } from '@shared/components/amortization-table/amortization-table.component';
import { LoaderComponent } from './loader/loader.component';
import { ShareAmortizationTableComponent } from '@shared/components/share-amortization-table/share-amortization-table.component';
import { CreditBureauAuthorizationComponent } from '@shared/components/credit-bureau-authorization/credit-bureau-authorization.component';
import { ApplicationDataSendComponent } from '@shared/components/application-data-send/application-data-send.component';
import { OfficialIdentificationComponent } from '@shared/components/official-identification/official-identification.component';
import { IncomeProofComponent } from '@shared/components/income-proof/income-proof.component';
import { AddressProofComponent } from '@shared/components/address-proof/address-proof.component';
import { TermsConditionsComponent } from '@shared/components/terms-conditions/terms-conditions.component';
import { PrivacyPolicyComponent } from '@shared/components/privacy-policy/privacy-policy.component';
import { ResendActivatedComponent } from './resend-activated/resend-activated.component';

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  configModal = {
    minWidth: '300px',
    minHeight: '250px',
    disableClose: true
  }
  params;
  close: string = 'Cerrar';
  accept: string = 'Aceptar';
  title: string = '';
  message: string = '';
  loaderState: boolean = false;
  constructor(private dlg: MatDialog) { }

  setWidth(params) {
    this.configModal['width'] = params
    return this;
  }

  setHeigth(params) {
    this.configModal['height'] = params
    return this;
  }

  setParams(params) {
    this.configModal["data"] = params
    return this;
  }

  setTextTitle(text: string) {
    this.title = text;
    return this;
  }

  setTextMessage(text: string) {
    this.message = text;
    return this;
  }

  labelButtonClose(label: string) {
    this.close = label;
    return this;
  }

  labelButtonAccept(label: string) {
    this.accept = label;
    return this;
  }

  success() {
    let modal = this.dlg.open(SuccessComponent, this.configModal);
    modal.componentInstance.accept = this.accept
    modal.componentInstance.title = 'Operación exitosa'
    modal.componentInstance.message = 'Su operación se realizo correctamente'
    return modal
  }

  error() {
    let modal = this.dlg.open(ErrorComponent, this.configModal);
    if (!this.message) {
      this.message = 'Algo salio mal, vuelve a intentarlo'

    }
    if (!this.title) {
      this.title = 'Error'
    }
    modal.componentInstance.close = this.close
    modal.componentInstance.accept = this.accept
    modal.componentInstance.title = this.title  // this.title
    modal.componentInstance.message = this.message
    return modal
  }

  confirm() {
    let modal = this.dlg.open(ConfirmComponent, this.configModal);
    modal.componentInstance.close = this.close
    modal.componentInstance.accept = this.accept
    modal.componentInstance.title = 'Confirmar'
    modal.componentInstance.message = this.message
    return modal
  }

  amortization() {
    let modal = this.dlg.open(AmortizationTableComponent, this.configModal);
    return modal;
  }

  loader() {
    if (!this.loaderState) {
      this.loaderState = true;
      let configModal = {
        minWidth: '150px',
        minHeight: '150px',
        disableClose: true
      }
      let modal = this.dlg.open(LoaderComponent, configModal)
      modal.afterClosed().subscribe(success => {
        this.loaderState = false;
      });
      return modal;
    }
    return { close() { } };
  }

  shareAmortizationTable() {
    let modal = this.dlg.open(ShareAmortizationTableComponent, this.configModal);
    modal.componentInstance.modalTitle = 'Envía tu cotización por correo'
  }

  bureauAothorization() {
    let modal = this.dlg.open(CreditBureauAuthorizationComponent, this.configModal);
    modal.componentInstance.title = 'Autorización Buró de Crédito'

    return modal
  }

  applicationDataSend() {
    let modal = this.dlg.open(ApplicationDataSendComponent, this.configModal);
    modal.componentInstance.title = 'Datos enviados con éxito'

    return modal
  }

  officialIdHelp() {
    let modal = this.dlg.open(OfficialIdentificationComponent, this.configModal);

    return modal
  }

  incomeProofHelp() {
    let modal = this.dlg.open(IncomeProofComponent, this.configModal);

    return modal
  }

  addressProofHelp() {
    let modal = this.dlg.open(AddressProofComponent, this.configModal);
    return modal
  }

  termsAndConditions() {
    let modal = this.dlg.open(TermsConditionsComponent, this.configModal);
  }

  privacyPolicy() {
    let modal = this.dlg.open(PrivacyPolicyComponent, this.configModal);
  }

  resendActivated() {
    let modal = this.dlg.open(ResendActivatedComponent, this.configModal);
    modal.componentInstance.modalTitle = 'Ingresa tu correo'
    return modal;
  }
}
