import { BasePortalHost } from '@angular/cdk/portal';

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,

  dominio: "https://yki6gpiyyd.execute-api.us-east-1.amazonaws.com/",
  basePath: "dev/",
  bucket_documents: "dev-wanacar-documents",
  urlLanding: "https://yki6gpiyyd.execute-api.us-east-1.amazonaws.com/dev/",
  bucket:{
    region: "us-east-1",
    name: "dev-wanacar-documents"
  },
  zipCodeUrl: "https://yki6gpiyyd.execute-api.us-east-1.amazonaws.com/dev/zipcode"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
