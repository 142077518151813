import { Component, OnInit, Input, Inject } from '@angular/core';
import { CarListService } from '@shared/services/http/car-list.service';
import { MatDialogRef } from '@angular/material';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'wn-share-amortization-table',
  templateUrl: './share-amortization-table.component.html',
  styleUrls: ['./share-amortization-table.component.scss']
})
export class ShareAmortizationTableComponent {
  @Input() modalTitle:string = "";
  public email: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private carSvc:CarListService,
    public dialogRef: MatDialogRef<ShareAmortizationTableComponent>
  ) { }

  sendAmortizationTable() {
    this.data.email = this.email
    this.carSvc.sendAmortizationTable(this.data).then(data => this.dialogRef.close())
  }

  closeModal() {
    this.dialogRef.close();
  }

}
