import { Component, OnInit, Input, HostListener, OnDestroy } from '@angular/core';
import { OpenClose } from '@shared/animations/OpenClose.animations';
import { Router, NavigationEnd } from '@angular/router';
import { AuthsessionService } from '@shared/services/auth/authsession.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'wn-navigation-bar',
  templateUrl: './navigation-bar.component.html',
  styleUrls: ['./navigation-bar.component.scss'],
  animations: [OpenClose]
})
export class NavigationBarComponent implements OnInit, OnDestroy {
  navbarTransparency = false;
  currentRoute = '';
  private subs: Subscription;
  redirect= true

  constructor(private route: Router, private sessionService: AuthsessionService) {
    this.subs = route.events.subscribe(e => {
      if (e instanceof NavigationEnd) {
        let url = e.url;
        this.currentRoute = url;
        this.navbarTransparency = false;
        this.redirect = true;
        if (url == '/') {
          this.redirect = false;
          this.navbarTransparency = true;
          this.navbarSiteLinks = this.menuApp;
        }
      }
    });
  }
  menuIsOpen = false;

  /* Objeto con los enlaces de la barra de navegación */
  navbarSiteLinks = []

  menuApp = [
    {
      name: 'Preguntas Frecuentes',
      link: '/faqs',
      specialButton: false
    },
    {
      name: 'ROUTES.simulator',
      link: '/simulator',
      specialButton: false
    },
    {
      name: 'Iniciar Sesión',
      link: '/auth/login',
      specialButton: true
    }
  ];

  menuSession = [
    {
      name: 'Preguntas Frecuentes',
      link: '/faqs',
      specialButton: false
    },
    {
      name: 'Cerrar Sesíon',
      specialButton: true,
      link: "/",
      closeSession: true
    }
  ];

  toggleMenuOpen() {
    this.menuIsOpen = !this.menuIsOpen;
  }

  execButton(item) {
    if (item.closeSession) {
      this.route.navigate(['/']).then(success =>{
        if(!success){
          location.reload()
        }
      })

      this.sessionService.clearAllSession();
    }
    switch (item.link) {
      case '/faqs':
        this.route.navigate([]).then(result => { window.open(item.link, '_blank'); });
        break;
      default:
        this.route.navigate([item.link]);
        break;
    }
  }

  /* Listener para determinar cuando la barra de navegación posee transparencia y cuando no */
  @HostListener('window:scroll', ['$event']) adjustNavTrasnparency(event) {
    let scrollPos = window.pageYOffset;

    if (scrollPos > 100 && this.currentRoute == '/') {
      this.navbarTransparency = false;
    } else if (scrollPos < 100 && this.currentRoute == '/') {
      this.navbarTransparency = true;
    }
  }

  ngOnInit() {
    let session = this.sessionService.setNameSession('userSession').getKeySession("id");
    if (session == null) {
      this.navbarSiteLinks = this.menuApp;
    } else {
      this.navbarSiteLinks = this.menuSession;
    }
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  goHome(){
    this.route.navigate(['/']).then(success =>{
      console.log(success)
    })
  }

}
