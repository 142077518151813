import { Injectable } from '@angular/core';
import { HttpService } from './http.service'
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private stateService = true;
  private unsubcribe: Subject<void> = new Subject();
  constructor(private httpSvc: HttpService) { }

  userRegister(mail, pass) {
    let params = {
      email: mail,
      password: pass
    }
    let path = "user";

    return this.httpSvc.post(path, params).toPromise();

  }

  logIn(mail, pass) {
    let params = {
      email: mail,
      password: pass
    }
    let path = "access/login";

    return this.httpSvc.post(path, params).toPromise();

  }

  resetPassword(email) {
    let path = "access/forgoot";
    let params = { email: email }
    return this.httpSvc.post(path, params).toPromise();
  }

  passwordApply(otp, password) {
    let path = "access/applyforgoot";
    let params = { otp: otp, password: password }
    return this.httpSvc.post(path, params).toPromise();
  }

  passwordAssign(otp, password) {
    let path = "access/assignPassword";
    let params = { otp: otp, password: password }
    return this.httpSvc.post(path, params).toPromise();
  }


  validOtp(otp: string) {
    let params = { otp: otp }
    let queryString = this.httpSvc.objToQueryString(params);
    let path = "access/validate?" + queryString;
    return this.httpSvc.get(path).toPromise();
  }

  resendOtp(otp?: string, email?: string) {
    let params = {}
    if (otp) {
      params['otp'] = otp;
    }
    if (email) {
      params['email'] = email;
    }

    let path = "user/resend";
    return this.httpSvc.post(path, params).toPromise();
  }

  getUserStep(step?: number) {
    let path = "user/step";
    if (step || step == 0) {
      path += "?step=" + step;
    }
    let header = this.httpSvc.loadToken();
    return this.httpSvc.get(path, header).toPromise();
  }

  updateDocument(document) {
    let path = "document";
    let header = this.httpSvc.loadToken();
    return this.httpSvc.post(path, document, header).toPromise();
  }

  userUpdate(user, step?:number) {
    let path = "user";
    let params = { data: user }
    if(step == 0  || step){
      params['step'] = step
    }
    let header = this.httpSvc.loadToken();
    return this.httpSvc.put(path, params, header).toPromise();
  }

  userGetTokensS3() {
    let path = "access/s3"
    let header = this.httpSvc.loadToken();
    return this.httpSvc.get(path, header).toPromise();
  }

  getUserData() {
    let path = "user"
    let header = this.httpSvc.loadToken();
    return this.httpSvc.get(path, header).toPromise();
  }

  getImageb64(name) {
    let path = "document?name_document=" + name;
    let header = this.httpSvc.loadToken();
    return this.httpSvc.get(path, header).toPromise();
  }
}
