import { Injectable } from '@angular/core';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class CarListService {
  constructor(private httpSvc: HttpService) { }

  getCarsList() {
    return this.httpSvc.get('simulator/cars').toPromise();
  }

  getCarsListFilter(filterField: string, searchText: string) {
    let params = {
      filter_by: filterField,
      search: searchText
    }
    let queryString = this.httpSvc.objToQueryString(params);
    let path = "simulator/cars?" + queryString;
    return this.httpSvc.get(path).toPromise();
  }

  getAmortizationTable(items: any) {
    return this.httpSvc.post('simulator/table', items).toPromise();
  }

  sendAmortizationTable(params: any) {
    return this.httpSvc.post('simulator/email', params).toPromise();
  }

  downloadAmortizationTable(params: any) {
    return this.httpSvc.post('simulator/pdf', params).toPromise();
  }
}
