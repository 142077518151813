import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '@shared/services/guards/auth.guard';
import { NoAuthGuard } from '@shared/services/guards/no-auth.guard';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('app/landing/landing.module').then(m => m.LandingModule)
  },
  {
    path: 'auth',
    loadChildren: () => import('app/authentication/authentication.module').then(m => m.AuthenticationModule),
    canActivate: [NoAuthGuard]
  },
  {
    path: 'application',
    loadChildren: () => import('app/credit-application/credit-application.module').then(m => m.CreditApplicationModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'request',
    loadChildren: () => import('app/credit-request/credit-request.module').then(m => m.CreditRequestModule),
    canActivate: [AuthGuard]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
