import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class AuthsessionService {
  private nameSession = "sessionUser";
  constructor(private cookieSvc: CookieService) { }

  setNameSession(name: string) {
    this.nameSession = name;
    return this;
  }

  setDataSession(user: any) {
    localStorage.setItem(this.nameSession, JSON.stringify(user))
    return this;
  }

  setDataCookie(user) {
    this.cookieSvc.set(this.nameSession, JSON.stringify(user))
  }

  getAllDataCookie() {
    return JSON.parse(this.cookieSvc.get(this.nameSession))
  }

  getAllData() {
    return JSON.parse(localStorage.getItem(this.nameSession))
  }

  getKeySession(key) {
    let data = this.getAllData();
    if (data != null) {
      if (data.hasOwnProperty(key)) {
        return data[key];
      }
    }
    return null;
  }

  setKeySession(key, value) {
    let data = this.getAllData();
    data[key] = value;
    this.setDataSession(data)
    return this;
  }

  clearSession() {
    localStorage.removeItem(this.nameSession);
    localStorage.removeItem(this.nameSession);
  }

  clearAllSession() {
    localStorage.clear();
  }

  isLogged() {
    let token = this.setNameSession('userSession').getKeySession('id_token');
    let id = this.setNameSession('userSession').getKeySession('id');
    return token && id;
  }
}
