import { trigger, state, transition, animate, style } from '@angular/animations';
export const OpenClose = trigger('openClose', [
        state('open', style({
        right: '0px'
        })),
        state('closed', style({
        right: '-100vw'
        })),
        transition('open => closed', [
        animate('0.3s')
        ]),
        transition('closed => open', [
        animate('0.3s')
        ]),
    ])