export const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const SIMULATOR = {
    YEAR: "model_years",
    BRAND: "make_name",
    NAME: "model_name",
    VERSION: "version"
}

export const STEP_ORIGINACION = {
    "simulator": 0,
    "personal_information": 1,
    "current_address":2,
    "working_information": 3,
    "references": 4,
    "documentation": 5,
    "confirm_resume": 6,
    "vehicle_data":7,
    "agency_data": 8,

}

export const USER_SESSION = "userSession"

export const ERRORS = {
    USER : "User does not exist.",
    CREDENTIAL: "Incorrect credentials"
}