import { Component, OnInit, Input, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {
  @Input() title:string ="";
  @Input() message:string = "";
  @Input() close:string ="";
  @Input() accept:string = "";
  
  constructor(public dialogRef: MatDialogRef<NotificationComponent>,
    @Inject(MAT_DIALOG_DATA) public data) { }

  ngOnInit() {
  }

  aceptar() {
    this.dialogRef.close(true);
  }

  cerrar(): void {
    let data = {
      uno:"dos",
      treces:"cuatro"
    }
    this.dialogRef.close(data);
  }


}
