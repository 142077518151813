import { Component, OnInit, Input } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'wn-application-data-send',
  templateUrl: './application-data-send.component.html',
  styleUrls: ['./application-data-send.component.scss']
})
export class ApplicationDataSendComponent implements OnInit {
  @Input() title:string = "";

  constructor(public dialogRef: MatDialogRef<ApplicationDataSendComponent>) { }

  ngOnInit() {
  }

  closeModal() {
    this.dialogRef.close();
  }

}
